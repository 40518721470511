import { Component } from 'react'

export default class Terms extends Component {
    render() {
        return (
            <section className="pt-md-11 pb-8 pb-md-14" style={{marginTop:'-50px',overflow:'scroll'}}>
                <div className="container login-page mt-9">
                    <div className="row">
                        <div className="col-12 col-md" style={{ textAlign: 'left' }}>
                            <h1 className="display-4 mb-3">Terms of Service</h1>
                            <p className="text-gray-800 mb-md-0">Updated 30-Sep-2020</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <hr className="my-6 my-md-8" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-11" style={{ textAlign: 'left' }}>
                            <p className="text-gray-800">These terms and conditions (“<b>Terms</b>”) govern the access to and use of the
                                Finvu mobile application and website (“<b>Platform</b>”) and the Services (<i>defined below</i>).
                            </p>
                            <p className="text-gray-800">These Terms also include our privacy policy, available at www.finvu.in
                                (“<b>Privacy Policy</b>”), and any guidelines, additional terms, policies, or disclaimers made
                                available or issued by us from time to time. These Terms may be amended by us in accordance with the
                                provisions hereunder, and the Terms, as amended from time to time, shall apply to you. You may
                                always review the current version of these Terms on the Platform.</p>
                            <p className="text-gray-800">These Terms constitute a binding and enforceable legal contract between
                                Cookiejar Technologies Private Limited (“<b>Company</b>”, “<b>we</b>”, or “<b>us</b>”) and any end
                                user of the Services (“<b>you</b>”). You represent and warrant that you (a) have full legal capacity
                                and authority to agree and bind yourself to these Terms, (b) are eighteen years of age or older, and
                                (c) are an Indian resident. If you represent an entity, organisation, or any other legal person, you
                                confirm and represent that you have the necessary power and authority to bind such entity,
                                organisation, or legal person to these Terms.</p>
                            <p className="text-gray-800">By using the Services, you agree that you have read, understood, and are bound
                                by these Terms, and that you comply with the requirements listed here. If you do not agree to all of
                                these Terms or comply with the requirements listed here, please do not access the Platform or use
                                the Services.</p>
                            <h3 className="mb-3">1. DEFINITIONS</h3>
                            <p className="text-gray-800">(a) “<b>AA Master Directions</b>” shall mean the Master Direction - Non-Banking
                                Financial Company - Account Aggregator (Reserve Bank) Directions, 2016 as amended, revised, or
                                updated from time to time;</p>
                            <p className="text-gray-800">(b) “<b>Financial Information</b>” shall have the same meaning ascribed to it
                                in the AA Master Directions;</p>
                            <p className="text-gray-800">(c) “<b>Financial Information Provider</b>” shall mean a bank, banking company,
                                non-banking financial company, asset management company, depository, depository participant,
                                insurance company, insurance repository, pension fund, or such other entity as may be identified as
                                a “<b>financial information provider</b>” in the AA Master Directions;</p>
                            <p className="text-gray-800">(d) “<b>Financial Information User</b>” shall mean an entity registered with
                                and regulated by any Financial Sector Regulator, or such other entity as may be identified as a
                                “<b>financial information user</b>” in the AA Master Directions;</p>
                            <p className="text-gray-800">(e) “<b>Financial Sector Regulator</b>” shall have the same meaning ascribed to
                                it in the AA Master Directions;</p>
                            <p className="text-gray-800">(f) “<b>RBI</b>” shall mean the Reserve Bank of India.</p>
                            <p className="text-gray-800">Words that are capitalised within the Terms but not defined in this Clause 1
                                shall have the meaning ascribed to them in the specific clause.</p>
                            <h3 className="mb-3">2. SCOPE</h3>
                            <p className="text-gray-800">(a) “<b>Account Aggregation Services</b>” : Through the Platform, you may:</p>
                            <div className="d-flex">
                                <span className="mt-1 mr-4">

                                </span>
                                <p className="text-gray-800">(i) retrieve, view, consolidate, organise, and share Financial Information
                                    that is available with Financial Information Providers; and</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(ii) present your Financial Information to Financial Information Users.</p>
                            </div>
                            <p className="text-gray-800">(b) The services offered by us through the Platform are referred to as
                                “<b>Services</b>” (which term also includes the provision of the Platform to you).</p>
                            <p className="text-gray-800">(c) For us to render the Services to you, you hereby authorise and appoint us
                                as your agent with limited power to access, with your consent, your Financial Information, sensitive
                                personal information, etc., from Financial Information Providers.</p>
                            <p className="text-gray-800">(d) We presently do not charge you any fees for your use of the Services.
                                However, we reserve the right to charge you for the Services in accordance with the applicable laws.
                                Should we intend to charge for the Services, the fees shall be in line with our pricing policy
                                available on our Platform. Additionally, any fees in the future shall be levied prospectively and
                                you will be provided a prior written notice in this case.</p>
                            <h3 className="mb-3">3. PROFILE CREATION</h3>
                            <p className="text-gray-800">(a) To avail the Services, you will be required to create a profile on the
                                Platform (“<b>Profile</b>”). In addition to setting up a username and password to create the
                                Profile, you will be required to furnish certain details, including but not limited to phone numbers
                                and other personal information. You warrant that all information furnished in connection with your
                                Profile is and shall remain accurate and true, and you agree that you shall promptly update your
                                details on the Platform in the event of any change to or modification of this information.</p>
                            <p className="text-gray-800">(b) You are solely responsible for maintaining the security and confidentiality
                                of your username and password and agree to immediately notify us of any disclosure or unauthorised
                                use of your Profile or any other breach of security with respect to your Profile.</p>
                            <p className="text-gray-800">(c) In case you are registering as a business or a corporate entity, you
                                represent that you are duly authorised by the business or corporate entity as applicable to accept
                                these Terms and you have the authority to bind that business or corporate entity to these Terms.</p>
                            <p className="text-gray-800">(d) You expressly agree to be liable and accountable for all activities that
                                take place through your Profile. We shall in no manner be liable for any unauthorised access to your
                                Profile.</p>
                            <h3 className="mb-3">4. KYC</h3>
                            <p className="text-gray-800">(a) We may be required to undertake certain “know your customer” processes in
                                relation to users of the Platform who intend to avail certain features of the Services. In this
                                regard, from time to time, we shall require you to upload information and documents that may be
                                necessary to ascertain your eligibility to use certain features of the Services (“<b>KYC
                                    Documents</b>”).</p>
                            <p className="text-gray-800">(b) You authorise us, and any third-party service provider we may engage with,
                                to process your KYC Documents and ascertain your eligibility. Any processing undertaken by us shall
                                be in accordance with our Privacy Policy. You agree that you may be subject to additional terms of
                                service in the event a third party processes information under this Clause. Further, in the event
                                any additional information, data, or documentation is required to determine eligibility
                                (collectively, “<b>Additional Information</b>”), you hereby agree to share such Additional
                                Information promptly upon request, and further, authorise us to process such Additional Information.
                            </p>
                            <p className="text-gray-800">(c) You agree and warrant to provide true, complete, and up-to-date KYC
                                Documents and Additional Information. You further acknowledge that (i) any false or misleading
                                information provided in this regard shall constitute a material breach of these Terms, and (ii) your
                                access to certain features of the Services may be limited or denied if you fail to share KYC
                                Documents and Additional Information.</p>
                            <h3 className="mb-3">5. OUR OBLIGATIONS</h3>
                            <p className="text-gray-800">(a) We shall provide the Services to you on the basis of your explicit consent.
                            </p>
                            <p className="text-gray-800">(b) We shall ensure that the provision of Services to you is backed by
                                appropriate agreements and/or authorisations between us, you, and the Financial Information
                                Providers.</p>
                            <p className="text-gray-800">(c) We do not store any Financial Information that relates to you and which we
                                may process in connection with the Services.</p>
                            <p className="text-gray-800">(d) We shall not use the services of a third-party service provider for
                                undertaking the business of account aggregation.</p>
                            <p className="text-gray-800">(e) We shall not access your user authentication credentials relating to
                                accounts with various Financial Information Providers.</p>
                            <h3 className="mb-3">6. CONSENT TO USE DATA</h3>
                            <p className="text-gray-800">(a) Notwithstanding anything to the contrary hereunder and in accordance with
                                the AA Master Directions, we do not store any Financial Information pertaining to you, except to the
                                extent necessary to make the Financial Information available to you or, upon your consent, to a
                                Financial Information User.</p>
                            <p className="text-gray-800">(b) The provision of our Services enables you to provide your consent to the
                                disclosure and transfer of your Financial Information. Consent is collected and confirmed through
                                the use of a consent artefact, which will require you to confirm the following datasets:</p>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(i) your identity and contact information;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(ii) the nature of the information requested;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4">
                                </span>
                                <p className="text-gray-800">(iii) purpose of collecting such information;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(iv) the identity of the recipients of the information;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(v) the consent creation date, the date of expiry of the consent, and your
                                    signature/digital signature;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(vi) any other information that may be required under applicable law.</p>
                            </div>
                            <p className="text-gray-800">(c) You may, at any point in time, revoke all or any of your consent(s)
                                provided to us through the consent artefact to obtain any Financial Information that relates to you.
                                Upon revocation, a fresh consent artefact shall be shared with the Financial Information Provider.
                            </p>
                            <p className="text-gray-800">(d) You may, at any point in time, access a record of the consents provided by
                                you and the Financial Information Users with whom the information has been shared on the Platform.
                            </p>
                            <p className="text-gray-800">(e) You agree that we may, in accordance with our Privacy Policy, collect and
                                use your personal information and technical data and related information.</p>
                            <p className="text-gray-800">(f) We may use information and data pertaining to your use of the Services for
                                analytics, trends’ identification, and statistics to further enhance the effectiveness and
                                efficiency of the Platform.</p>
                            <p className="text-gray-800">(g) Subject to applicable laws, we may be directed by law enforcement agencies
                                or the government and related bodies to disclose data related to you in connection with criminal
                                proceedings. You understand and agree that in such instances, we shall have the right to share such
                                data with relevant agencies or bodies.</p>
                            <h3 className="mb-3">7. YOUR RESPONSIBILITIES</h3>
                            <p className="text-gray-800">(a) You represent and warrant that all information that is provided through or
                                in relation to the Services is complete, true, and correct on the date of agreeing to these Terms
                                and shall continue to be complete, true, and correct while you avail the Services. Should any
                                information that you provide change during the existence of these Terms, you undertake to
                                immediately bring such change to our notice. We do not accept any responsibility or liability for
                                any loss or damage that you may suffer or incur if any information, documentation, material, or data
                                provided to avail the Services is incorrect, incomplete, inaccurate, or misleading, or if you fail
                                to disclose any material fact.</p>
                            <p className="text-gray-800">(b) You shall be solely responsible for ensuring compliance with applicable
                                laws and shall be solely liable for any liability that may arise due to a breach of your obligations
                                in this regard.</p>
                            <p className="text-gray-800">(c) You shall extend all cooperation to us in our defence of any proceedings
                                that may be initiated against us due to a breach of your obligations or covenants under these Terms.
                            </p>
                            <p className="text-gray-800">(d) You shall not use the Services in any manner except as expressly permitted
                                in these Terms. Without limiting the generality of the preceding sentence, you shall not:</p>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(i) use the Services to transmit any data or send or upload any material
                                    that contains viruses, trojan horses, worms, timebombs, keystroke loggers, spyware, adware, or
                                    any other harmful programmes or similar computer code designed to adversely affect the operation
                                    of any computer software or hardware;</p>
                            </div>
                            <div className="d-flex"><span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(ii) use any robot, spider, other automated device, or manual process to
                                    monitor or copy the Platform or any portion thereof;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(iii) engage in the systematic retrieval of content from the Platform to
                                    create or compile, directly or indirectly, a collection, compilation, database, or directory;
                                </p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(iv) use the Services in (A) any unlawful manner, (B) for fraudulent or
                                    malicious activities, or (C) in any manner inconsistent with these Terms; or</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4">
                                </span>
                                <p className="text-gray-800">(v) violate applicable laws in any manner.</p>
                            </div>
                            <p className="text-gray-800">(e) You warrant that you shall not engage in any activity that interferes with
                                or disrupts access to the Platform.</p>
                            <p className="text-gray-800">(f) You shall not attempt to gain unauthorised access to any portion or feature
                                of the Platform, any other systems or networks connected to the Platform, to any of our servers, or
                                through the Platform, by hacking, password mining, or any other illegitimate means.</p>
                            <h3 className="mb-3">8. INTELLECTUAL PROPERTY</h3>
                            <p className="text-gray-800">(a) All rights, title, and interest in and to the Platform and Services,
                                including all intellectual property rights arising out of the Platform and Services, are owned by,
                                or otherwise licensed to us. Subject to compliance with these Terms, we grant you a non-exclusive,
                                non-transferable, non-sub licensable, royalty free, revocable, and limited licence to use the
                                Platform and Services in accordance with these Terms and its written instructions issued from time
                                to time.</p>
                            <p className="text-gray-800">(b) We may request you to submit suggestions and other feedback, including bug
                                reports, relating to the Platform or Services from time to time (“<b>Feedback</b>”). We may freely
                                use, copy, disclose, publish, display, distribute, and exploit the Feedback without any payment of
                                royalty, acknowledgement, prior consent, or any other form of restriction arising out of your
                                intellectual property rights.</p>
                            <p className="text-gray-800">(c) Except as stated in these Terms, nothing in these Terms should be construed
                                as conferring any right in or licence to our or any third party’s intellectual property rights.</p>
                            <h3 className="mb-3">9. TERM AND TERMINATION</h3>
                            <p className="text-gray-800">(a) These Terms shall remain in effect unless terminated in accordance with the
                                terms hereunder.</p>
                            <p className="text-gray-800">(b) We may terminate your access to or use of the Services, or any portion
                                thereof, immediately and at any point, at our sole discretion if you violate or breach any of the
                                obligations, responsibilities, or covenants under these Terms, or when you cease to become a user of
                                our Services.</p>
                            <p className="text-gray-800">(c) Upon termination of these Terms:</p>
                            <div className="d-flex"><span className="mt-1 mr-4">
                            </span>
                                <p className="text-gray-800">(i) the Profile will expire;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4">
                                </span>
                                <p className="text-gray-800">(ii) the Services will “time-out”; and</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(iii) these Terms shall terminate, except for those clauses that expressly
                                    or are intended to survive termination or expiry.</p>
                            </div>
                            <h3 className="mb-3">10. DISCLAIMERS AND WARRANTIES</h3>
                            <p className="text-gray-800">(a) We do not warrant the accuracy, suitability, or correctness of any
                                Financial Information that is made available on or through the Services.</p>
                            <p className="text-gray-800">(b) The Platform and the Services are provided by us on an “as is” basis
                                without warranty of any kind, express, implied, statutory or otherwise, including the implied
                                warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without
                                limiting the foregoing, we make no warranty that:</p>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(i) the Platform or the Services will meet your requirements or
                                    expectations, or that your use of the Services will be uninterrupted, timely, secure, or
                                    error-free;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(ii) any errors or defects in the Platform will be corrected.</p>
                            </div>
                            <p className="text-gray-800">(c) No advice or information, whether oral or written, obtained by you from us
                                shall create any warranty that is not expressly stated in the Terms.</p>
                            <p className="text-gray-800">(d) You hereby accept full responsibility for any consequences that may arise
                                from your use of the Services, and expressly agree and acknowledge that we shall have absolutely no
                                liability in this regard.</p>
                            <p className="text-gray-800">(e) To the fullest extent permissible by law, we, our affiliates, and related
                                parties each disclaim all liability towards you for any loss or damage arising out of or due to:</p>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(i) your use of, inability to use, or availability or unavailability of the
                                    Services;</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(ii) the occurrence or existence of any defect, interruption, or delays in
                                    the operation or transmission of information to, from, or through the Services, communications
                                    failure, theft, destruction or unauthorised access to our records, programmes, services, server,
                                    or other infrastructure relating to the Services; or</p>
                            </div>
                            <div className="d-flex">
                                <span className="mt-1 mr-4"></span>
                                <p className="text-gray-800">(iii) the failure of the Services to remain operational for any period of
                                    time.</p>
                            </div>
                            <p className="text-gray-800">(f) Notwithstanding anything to the contrary contained herein, neither we nor
                                any of our affiliates or related parties shall have any liability to you or any third party for any
                                indirect, incidental, special or consequential damages or any loss of revenue or profits arising
                                under, directly or indirectly, or relating, in any manner whatsoever, to these Terms or the
                                Services. To the maximum extent permitted by law, you agree to waive, release, discharge, and hold
                                harmless us, our affiliated and subsidiary companies, their parent companies, and each of their
                                directors, officers, employees, and agents, from any and all claims, losses, damages, liabilities,
                                expenses and causes of action arising out of the Services.</p>
                            <h3 className="mb-3">11. INDEMNITY</h3>
                            <p className="text-gray-800">You shall indemnify, defend at our option, and hold us, our parent company,
                                subsidiaries, affiliates, and their officers, employees, directors, agents, and representatives,
                                harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities,
                                damages. and costs (including, without limitation, all damages, liabilities, settlements, and
                                attorneys’ fees) due to or arising out of your access to the Services, use of the Services,
                                violation of these Terms or any infringement of these Terms by any third party who may use your
                                Profile to access the Services.</p>
                            <h3 className="mb-3">12. MODIFICATION</h3>
                            <p className="text-gray-800">We reserve the right at any time to add, modify or discontinue, temporarily or
                                permanently, the Services (or any part thereof) with or without cause. We shall not be liable for
                                any such addition, modification, suspension, or discontinuation of the Services.</p>
                            <h3 className="mb-3">13. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION</h3>
                            <p className="text-gray-800">(a) These Terms shall be governed by and construed and enforced in accordance
                                with the laws of India. Subject to other provisions in this Clause, courts in Pune shall have
                                exclusive jurisdiction over all issues arising out of these Terms or the use of the Services.</p>
                            <p className="text-gray-800">(b) Any controversies, conflicts, disputes, or differences arising out of these
                                Terms shall be resolved by arbitration in Pune in accordance with the Arbitration and Conciliation
                                Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this
                                Clause. The tribunal shall consist of 1 (one) arbitrator appointed by us. The language of the
                                arbitration shall be English.</p>
                            <p className="text-gray-800">(c) The parties to the arbitration shall keep the arbitration confidential and
                                not disclose to any person, other than on a need to basis or to legal advisors, unless required to
                                do so by law. The decision of the arbitrator shall be final and binding on all the parties hereto.
                            </p>
                            <p className="text-gray-800">(d) Each party to the arbitration shall bear its own costs with respect to any
                                dispute.</p>
                            <h3 className="mb-3">14. GRIEVANCE REDRESSAL</h3>
                            <p className="text-gray-800">(a) You may contact our designated Grievance Redressal Officer with any
                                complaints or queries relating to the Services or these Terms through registered post or through
                                email, details of which are provided below:</p>
                            <p className="text-gray-800"><b>Email Address:</b> support@cookiejar.co.in</p>
                            <p className="text-gray-800"><b>Registered Address:</b> Kumar Vastu F-801 Sr.No-209/1a,Bhamburda, Near
                                Bhosale Nagar, Range Hills, Pune, Maharashtra, India, 411030</p>
                            <p className="text-gray-800">(b) We shall ensure that your complaint is resolved within 30 (thirty) days
                                from the date of the complaint. In the event the complaint is not redressed within such period, you
                                may appeal to the RBI in accordance with the applicable regulations.</p>
                            <h3 className="mb-3">15. MISCELLANEOUS PROVISIONS</h3>
                            <p className="text-gray-800">(a) <b>Modification:</b> We reserve the right at any time to modify these Terms
                                and to add new or additional terms or conditions on use of the Services. Such modifications and
                                additional terms and conditions will be communicated to you and, unless expressly rejected (in which
                                these Terms shall terminate), will be effective immediately and will be incorporated into these
                                Terms. In the event you refuse to accept such changes, these Terms will terminate.</p>
                            <p className="text-gray-800">(b) <b>Severability:</b> If any provision of these Terms is determined by any
                                court or other competent authority to be unlawful or unenforceable, the other provisions of these
                                Terms will continue in effect. If any unlawful or unenforceable provision would be lawful or
                                enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the
                                provision will continue in effect (unless that would contradict the clear intention of the clause,
                                in which case the entirety of the relevant provision will be deemed to be deleted).</p>
                            <p className="text-gray-800">(c) <b>Assignment:</b> You shall not license, sell, transfer, or assign their
                                rights, obligations, or covenants under these Terms in any manner without our prior written consent.
                                We may grant or withhold this consent at our sole discretion and subject to any conditions we deem
                                appropriate. We may assign our rights to any of our affiliates, subsidiaries, or parent companies,
                                or to any successor in interest of any business associated with the Services without any prior
                                notice to the you.</p>
                            <p className="text-gray-800">(d) <b>Notices:</b> All notices, requests, demands, and determinations for us
                                under these Terms (other than routine operational communications) shall be sent to
                                info@cookiejar.co.in</p>
                            <p className="text-gray-800">(e) <b>Third Party Rights:</b> No third party shall have any rights to enforce
                                any terms contained herein.</p>
                            <p className="text-gray-800">(f) <b>Force Majeure:</b> We shall have no liability to you if we are prevented
                                from or delayed in performing our obligations or from carrying on our business by acts, events,
                                omissions, or accidents beyond our reasonable control, including, without limitation, strikes,
                                failure of a utility service or telecommunications network, act of God, war, riot, civil commotion,
                                malicious damage, compliance with any law or governmental order, rule, regulation, or direction.</p>
                            <p className="text-gray-800">(g) <b>Translations:</b> We may provide you with translated versions of these
                                Terms solely to assist you with understanding these Terms in greater detail. The English version of
                                these Terms shall be controlling in all respects. In the event of any inconsistency between the
                                English version of these Terms and any translated version, the terms of the English version shall
                                prevail.</p>
                        </div>
                    </div>
                </div>
                {/* <div className="position-fixed w-100" style={{bottom:'20px'}}>
                            <button type="button" className="btn btn-lg btn-block fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }} onClick={() => { this.props.history.push({pathname: `/login`,state:{fromTerm:true}}) }}>BACK</button>
                        </div> */}
            </section>
        )
    }
}
