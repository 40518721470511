import { Component } from 'react'
import AuthApis from '../helper/api.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';

export default class Revoke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAccounts: [],
      selectedAccounts: [],
      isDisable: false,
      detailSpinner: false,
      revokeSpinner: false,
      consentIntentId: null,
      consentNotFound: false,
      isLogout: false,
      mobileNumber: '',
      AAHandle: ''
    };
    this.lastIndex = 0
    this.authApis = new AuthApis()
  }
  componentDidMount() {
    this.getLiveConsents()
  }
  getLiveConsents = () => {
    this.props.updateLoader(true)
    this.authApis.userActiveConsents().then(res => {
      console.log("res", res);
      if (res && res?.status === 'SUCCESS') {
        console.log(res.message)
        this.getEntityInfo(res.userConsents)
      } else if (res && res?.status === 'RECORD-NOT-FOUND') {
        this.logout()
        this.setState({ consentNotFound: true })
        this.props.updateLoader(false)
      } else {
        if (res?.message === "Login Required") {
          this.props.history.push('/')
        }
        toast.error(res?.message)
        this.props.updateLoader(false)
        console.log(res?.message)
      }
    })

  }


  getEntityInfo = async (payload) => {
    let data = payload
    for (let i = 0; i < payload.length; i++) {
      await this.authApis.entityInfo(payload[i].consentIntentEntityId, 'FIU').then(res => {
        console.log("res", res);
        if (res?.status && res?.status === 'SUCCESS') {
          data[i].entityLogoUri = res.entityLogoUri
          if (i === (payload.length - 1)) {
            this.setState({ allAccounts: data, selectedAccounts: this.props?.location?.state?.selectedAccounts ? this.props?.location?.state?.selectedAccounts : [] })
            this.getUserInfo()
          }
        } else {
          this.props.updateLoader(false)

          toast.error(res.message)
          console.log(res.message)
        }
      })

    }
  }


  getUserInfo = async () => {
    await this.authApis.getUserDetails().then(res => {
      console.log("res", res);
      this.props.updateLoader(false)
      if (res?.status && res?.status === 'ACCEPT') {
        console.log(" user info - ", res.message)
        this.setState({ mobileNumber: res.UserInfo.mobileNo, AAHandle: res.UserInfo.userId })
      } else {

        toast.error(res)
        console.log(res)
      }
    })
  }


  selectUnselectAcc = (el) => {
    // let data =[el]
    let data = this.state.selectedAccounts
    if (this.state.selectedAccounts.map(elm => { return elm?.consentIntentId }).includes(el?.consentIntentId)) {
      let index = data.map(elm => { return elm.consentIntentId }).indexOf(el?.consentIntentId)
      data.splice(index, 1)
    } else {
      data.push(el)
    }
    this.lastIndex = data?.length - 1;
    console.log("this.lastIndex - ", this.lastIndex)
    this.setState({ selectedAccounts: data })
  }

  revoke = async () => {
    this.setState({ revokeSpinner: true })
    this.props.updateLoader(true)
    for (let i = 0; i < this.state.selectedAccounts?.length; i++) {
      let consentIntentId = this.state.selectedAccounts[i].consentIntentId
      console.log("this.state.selectedAccounts[i].consentIntentId - ", this.state.selectedAccounts[i].consentIntentId)
      await this.authApis.revokeConsent(consentIntentId).then(res => {
        console.log("res", res);
        if (res?.status && res?.status === 'SUCCESS') {
          console.log(res.message)
          if (consentIntentId === this.state.selectedAccounts[this.lastIndex].consentIntentId) {
            this.props.updateLoader(false)
            this.setState({ revokeSpinner: false })
            this.props.history.push({
              pathname: `/status`,
              state: { number: this.state.mobileNumber }
            });
          }
        } else {
          toast.error(res.message)
          console.log(res.message)
          this.props.updateLoader(false)

          if (res.message === 'Login Required') {
            this.props.history.push('/')
          }
          if (i === (this.state.selectedAccounts?.length - 1)) {
            this.setState({ revokeSpinner: false })
          }
        }
      })
    }
  }

  dateFormat(dateString) {
    if (dateString) {
      let newDate = new Date(dateString);
      let dataArray = newDate.toString().split(' ');
      return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
    }
  }
  consentDetails = (id) => {
    this.props.updateLoader(true)
    this.authApis.consentDetails(id).then(res => {
      console.log("res", JSON.stringify(res));
      if (res?.status && res?.status === 'SUCCESS') {
        this.getEntityFIPInfo(res)
      } else {
        this.props.updateLoader(false)
        toast.error(res.message)
        console.log(res.message)
      }
    })
  }
  getEntityFIPInfo = async (payload) => {
    let data = payload
    for (let i = 0; i < data.Accounts.length; i++) {
      await this.authApis.entityInfo(data.Accounts[i].fipId, 'FIP').then(res => {
        console.log("res", res);
        data.Accounts[i].entityName = res.entityName
        if (res?.status && res?.status === 'SUCCESS') {
          console.log(res.message)
          if (i === (data.Accounts.length - 1)) {
            this.props.history.push({
              pathname: `/consent-details`,
              state: { data: data, selectedAccounts: this.state.selectedAccounts, number: this.state.mobileNumber }
            });
            this.props.updateLoader(false)
          }
        } else {
          this.props.updateLoader(false)
          toast.error(res.message)
          console.log(res.message)
        }
      })

    }
  }
  logout = () => {
    this.authApis.logout(this.state.AAHandle).then(res => {
      console.log("res", res);
      postMessage('window', 'CLOSED')
      if (res.status === 'SUCCESS') {
        this.props.history.push('/')
        console.log(res.message)
      } else {
        toast.error(res.message)
        console.log(res.message)
      }
    })
  }
  render() {
    return (
      <div>
        <div className="container mt-10">
          <div className='row' style={{ paddingBottom: '118px' }}>
            <div className='col-lg-6 offset-lg-3 col-12'>
              <div className='px-3'>
                <h1 className='fs-14 fw-600 text-start mt-3' style={{ color: '#4F4F4F' }}>REVOKE ACTIVE CONSENTS</h1>
                <p className="fs-12 fw-500 text-start mt-2" style={{ color: '#BDBDBD' }}> <span className='me-2'>AA handle</span> <span>{this.state?.AAHandle}</span> </p>
                {
                  !this.state.consentNotFound ?
                    <p className="fs-14 fw-500 text-start mt-3" style={{ color: '#828282' }}>Select to revoke access on your accounts </p>
                    : null
                }
              </div>
              {
                this.state.allAccounts?.length ?
                  <div>
                    <div className="row px-2">
                      {
                        this.state.allAccounts.map((el, i) => {
                          return (
                            <div className="col-12 mb-3" key={i}>
                              <div className='border border-1 border-black rounded-3 p-2 py-md-2 px-md-4 py-lg-2 px-lg-4'>
                                <div className="row p-2">
                                  <div className="col-6">
                                    <img src={el?.entityLogoUri} className="float-start mt-2" style={{ width: 'auto', height: 'auto', objectFit: 'contain' }} alt='Logo_URL' />
                                  </div>
                                  {/* <div className="col-lg-8 col-7" style={{ alignSelf: "center" }}>
                              <h1 className="fs-16 fw-600 text-start text-dark" style={{ fontFamily: this.props.customDetails?.fontFamily }}>{el?.consentIntentEntityName}</h1>
                            </div> */}
                                  <div className="col-6" style={{ alignSelf: "center" }}>
                                    <div className="form-check form-check-inline float-end">
                                      <span className={`form-check-input ${this.state.selectedAccounts.map(elm => { return elm?.consentIntentId }).includes(el.consentIntentId) ? 'selected-circle border-0' : 'border-circle'} `} onClick={() => { this.selectUnselectAcc(el) }}></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row p-2">
                                  <div className='col-12'>
                                    <p className='fw-700 text-info text-start'>{el?.consentIntentEntityName}</p>
                                  </div>
                                  <div className='col-6'>
                                    <p className='fs-12 fw-600 text-start mb-2' style={{ color: '#828282' }}>Purpose</p>
                                  </div>
                                  <div className='col-6'>
                                    <p className='fs-12 fw-600 text-end mb-2' style={{ color: '#828282' }}>Consent given on</p>
                                  </div>
                                  <div className='col-7'>
                                    <p className='fs-12 fw-600 text-black text-start'>{el?.consentPurposeText}</p>
                                  </div>
                                  <div className='col-5'>
                                    <p className='fs-12 fw-600 text-black text-end'>{this.dateFormat(el?.consentIntentUpdateTimestamp)}</p>
                                  </div>
                                  <div className='col-12'>
                                    <button className='fs-16 fw-700 bg-transparent border-0 rounded-3 bleu-text-color float-end' onClick={() => { this.consentDetails(el?.consentIntentId) }} >DETAILS</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                  </div>
                  :
                  null
              }
              {
                this.state.consentNotFound ?

                  <div className='col-12'>
                    <div className='row py-5 px-3'>
                      <div className='col-lg-12 col-12'>
                        <h1 className='fs-20 fw-600 text-center mb-4' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>No consents found</h1>
                        <p className='fs-14 fw-500 text-start mt-2 mb-4' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>We could not find active consents, and could be due to either:</p>
                        <ul className='mt-2'>
                          <li className='fs-15 fw-500 text-start mb-2' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily, fontSize: '14px' }}>
                            You have already revoked all consents
                          </li>
                          <li className='fs-15 fw-500 text-start' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily, fontSize: '14px' }}>
                            You have not provided any consent
                          </li>
                        </ul>

                      </div>

                      <div className='col-12 mt-5' style={{ display: 'flex', justifyContent: 'center' }}>
                        <button disabled={this.state.isLogout} type="button" className="btn px-5 fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, display: 'flex', fontFamily: this.props.customDetails?.fontFamily, justifyContent: 'center' }}
                          onClick={() => { this.setState({ isLogout: true }) }} >
                          OKAY
                        </button>
                      </div>
                      {
                        this.state.isLogout ?
                          <p className='fs-14 fw-600 text-danger mt-5'> You are logged out. You may close this window</p>
                          : null
                      }
                    </div>
                  </div>
                  : null
              }

            </div>
          </div>
        </div>
        {
          this.state.allAccounts?.length ?
            <div>
              <div className='accept-row' style={{ width: '100%' }}>

                <div className="pt-2">
                  <button type="button" disabled={!(this.state.selectedAccounts?.length) || this.state.isDisable} onClick={this.revoke} className="btn px-5 fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }}>
                    {
                      (this.state.isDisable) ?
                        <div className="snippet" style={{ display: 'flex', justifyContent: 'center' }} data-title=".dot-pulse">
                          <div className="stage">
                            <div className="dot-pulse-btn"></div>
                          </div>
                        </div>
                        :
                        'REVOKE'
                    }

                  </button>
                </div>
                <div className="pt-2">
                  <button type="button" style={{ fontFamily: this.props.customDetails?.fontFamily }} className="btn btn-lg fs-14 w-100 fw-600 bleu-text-color" onClick={this.logout}>
                    EXIT
                  </button>
                </div>
              </div>
            </div>
            : null
        }
      </div>
    )
  }
}
