import { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import Status from './components/Status';
import Revoke from './components/Revoke';
import Login from './components/Login';
import Header from './components/common/Header';
import ConsentDetails from './components/ConsentDetails';
const defaultConfig = {
  "fontFamily": "Lato",
  "primaryColor": "#017aff",
  "secondaryColor": "#F5F5F5",
  "headingColor": "#191c1f",
  "subHeadingColor": "#0e65d7",
  "textColor": "#757779",
  "btnHeight": "40px",
  "btnWidth": "327px",
  "borderRadius": "40px"
}
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoader: false,
      configDetails: defaultConfig
    }
  }
  updateLoader = (val) => {
    this.setState({ isLoader: val })
  }
  render() {
    return (
      <div className="App" style={{ fontFamily: this.state.configDetails?.fontFamily }}>
        <Header customDetails={this.state.configDetails} />
        <Switch>
          <Route exact path="/" render={(props) => <Login {...props} customDetails={this.state.configDetails} />} />
          <Route exact path="/revoke-consent" render={(props) => <Revoke {...props} updateLoader={this.updateLoader} customDetails={this.state.configDetails} />} />
          <Route exact path="/status" render={(props) => <Status {...props} updateLoader={this.updateLoader} customDetails={this.state.configDetails} />} />
          <Route exact path="/consent-details" render={(props) => <ConsentDetails {...props} customDetails={this.state.configDetails} />} />
        </Switch>
         {/* To display Success, Warning or Error messages to user */}
         <div className="toast-message" >
          <ToastContainer />
        </div>
         {/* To display API call Loader */}
         {
          this.state.isLoader ?
            <div>
              <div className="application-loading-container">
              <div className="snippet" data-title=".dot-pulse">
                  <div className="stage">
                    <div className="dot-pulse-btn"></div>
                  </div>
                </div>
                {/* <img className="image" src={LoaderImage} alt="" width="120" height="120"></img> */}
              </div>
            </div>
            :
            null
        }
      </div>
    );
  }

}

export default App;
